import React from 'react';
import { Link } from 'gatsby';
import { BgImage } from 'gbimage-bridge';
import './HoverEffect.css';

export default function HoverEffect({ slug, image, title }) {
	return (
		<div className="flex-1">
			<Link to={slug}>
				<BgImage image={image}>
					<div className="background-div bg-black bg-opacity-60 opacity-0 hover:opacity-100 duration-300 inset-0 z-10 flex justify-center items-center text-white">
						<h3> {title}</h3>
					</div>
				</BgImage>
			</Link>
		</div>
	);
}
